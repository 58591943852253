<template>
  <v-app>
    <v-card width="98%" class="mx-auto mt-5 pa-5">
    <form>
      <h3>認証コードの送信</h3>
      <p>本人確認のためSMSで認証コードを送信します。</p>
      <p>

        番号を入力する際はハイフン（-）は含めずにご入力ください。また、海外の電話番号は使用することはできませんのでご了承ください。
      </p>
      <v-text-field
        v-model="phoneNumber"
        label="携帯電話番号"
        placeholder="0901234567"
        maxlength="11"
        outlined
        dense
      ></v-text-field>
      <v-btn
        id="send-code-button"
        @click="sendPhoneCode"
        prepend-icon="mdi-phone"
      >
        認証コードを送信する
      </v-btn>
      <p class="ma-5">※SMS送信時に料金が発生致しますのでご了承ください。</p>
    </form>
    </v-card>
  </v-app>
</template>

<script>

import Firebase from 'firebase';
import "@firebase/auth";

export default {
  data() {
    return {
      countryCode: "ja",
      phoneNumber: '',
      recaptchaVerifier: null
    }
  },
  mounted() {
    this.recaptchaVerifier = new Firebase.auth.RecaptchaVerifier('send-code-button', {
      size: 'invisible'
    })
  },
  methods: {
    async sendPhoneCode() {
      if (!this.phoneNumber) {
        alert('携帯電話の番号を入力してください')
      }
      const formattedPhpneNumber = this.countryCode + this.phoneNumber
        const confirmationResult = await Firebase.auth().signInWithPhoneNumber(formattedPhpneNumber, this.recaptchaVerifier)
        window.sessionStorage('result', confirmationResult)
        this.$router.push('confirm')

    }
  }
}
</script>
